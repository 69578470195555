/* eslint-disable no-nested-ternary */
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { useInView } from "framer-motion";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { usePosthogEvent } from "../../../hooks/useTrackingEvent";
import { RVOTrackingEvent } from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import { FeaturedVideoProps } from "./FeaturedVideoInterface";

const FeaturedVideo = ({
  videoUrl,
  posterImage,
  playing,
  minimizeOnlyWhilePlaying,
  schemaTitle,
  schemaDescription,
  schemaUploadDate,
  schemaContentUrl,
  schemaThumbnailUrl,
  schemaRegionsAllowed,
}: FeaturedVideoProps): JSX.Element | null => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isPlaying, setIsPlaying] = useState(false);
  const { firePosthogEvent } = usePosthogEvent();
  const handlePlay = () => {
    setIsPlaying(true);
    firePosthogEvent({
      eventId: RVOTrackingEvent.VideoPlayed,
      additionalProps: {
        videoUrl,
        posterImage,
      },
    });
  };
  const handlePause = () => {
    setIsPlaying(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // schema markup for video
  const schema = {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    name: `${schemaTitle || ""}`,
    thumbnailUrl: `${schemaThumbnailUrl || ""}`,
    uploadDate: `${schemaUploadDate || ""}`,
    description: `${schemaDescription || ""}`,
    contentUrl: `${schemaContentUrl || ""}`,
    regionsAllowed: `${schemaRegionsAllowed || ""}`,
  };

  return videoUrl ? (
    <>
      <Helmet>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      </Helmet>

      <Grid
        container={true}
        style={{
          justifyContent: "center",
          marginTop: theme.spacing(-5),
          position: "relative",
          marginBottom: theme.spacing(2),
          [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(-3),
          },
          width: isMobile ? "80%" : "60%",
          margin: "0 auto",
          aspectRatio: "16 / 9",
        }}
        ref={ref}
      >
        <Box
          style={
            isInView
              ? {
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    marginTop: theme.spacing(2),
                  },
                }
              : (minimizeOnlyWhilePlaying && isPlaying) ||
                !minimizeOnlyWhilePlaying
              ? {
                  width: isMobile ? "70vw" : "25vw",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  zIndex: 1000,
                }
              : { display: "none" }
          }
        >
          <ReactPlayer
            onPlay={handlePlay}
            onPause={handlePause}
            url={videoUrl}
            playing={playing}
            controls={true}
            width="640px"
            height="360px"
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  poster: posterImage,
                },
              },
            }}
          />
        </Box>
      </Grid>
    </>
  ) : null;
};

export default FeaturedVideo;
